import { Controller } from "@hotwired/stimulus";
import Toastify from "toastify-js";

class ClipboardController extends Controller {
  static values = {
    content: String,
  };

  copy() {
    if (navigator) {
      navigator.clipboard.writeText(this.contentValue);
      Toastify({
        text: "Copied to clipboard",
        duration: 3000,
        style: {
          fontSize: "12px",
          borderRadius: "4px",
          background: "#427EEF",
        },
      }).showToast();
    }
  }
}

export default ClipboardController;
