import { Controller } from "@hotwired/stimulus";

class ChromeExtensionPlayChapter extends Controller {
  static values = {
    timestamp: String,
  };

  play() {
    const event = {
      action: "playTimestamp",
      timestamp: this.timestampValue,
    };

    window.parent.postMessage(event, "*");
  }
}

export default ChromeExtensionPlayChapter;
