import { Application } from "@hotwired/stimulus";
import Clipboard from "./clipboard";
import DarkMode from "./dark_mode";
import ChromeExtensionPlayChapter from "./chrome_extension_play_chapter";

const application = Application.start();
application.register("clipboard", Clipboard);
application.register("dark-mode", DarkMode);
application.register(
  "chrome-extension-play-chapter",
  ChromeExtensionPlayChapter
);
