import { Controller } from "@hotwired/stimulus";

class DarkModeController extends Controller {
  connect() {
    this.applyThemePreference(this.getThemePreference());
  }

  toggle() {
    const themePreference = this.getThemePreference();
    const nextThemePreference = themePreference === "dark" ? "light" : "dark";
    this.setUserThemePreference(nextThemePreference);
    this.applyThemePreference(nextThemePreference);
  }

  getThemePreference() {
    // Check cookie first b/c this is how URL override works
    const cookieSettingExists = document.cookie.indexOf("theme=") !== -1;
    if (cookieSettingExists) {
      const cookieSetting = document.cookie.includes("theme=dark")
        ? "dark"
        : "light";
      return cookieSetting;
    }

    const userPreference = localStorage.getItem("themePreference");
    if (userPreference) {
      return userPreference;
    }

    const systemPreference = window.matchMedia("(prefers-color-scheme: dark)")
      .matches
      ? "dark"
      : "light";

    return systemPreference;
  }

  setUserThemePreference(preference) {
    localStorage.setItem("themePreference", preference);
    document.cookie = "theme=" + preference + "; SameSite=None; Secure; path=/";
  }

  applyThemePreference(preference) {
    const isDarkMode = preference === "dark";
    document.documentElement.classList.toggle("dark", isDarkMode);
  }
}

export default DarkModeController;
